import React, { useState } from 'react';
import { Bot, X } from 'lucide-react';
import { SalesAssistant } from './SalesAssistant';

export function FloatingChatWidget() {
  const [isOpen, setIsOpen] = useState(false);

  if (!isOpen) {
    return (
      <button
        onClick={() => setIsOpen(true)}
        className="fixed bottom-4 right-4 bg-blue-600 text-white rounded-full p-3 shadow-lg hover:bg-blue-500 transition-colors z-50"
      >
        <Bot className="h-6 w-6" />
      </button>
    );
  }

  return (
    <div className="fixed bottom-4 right-4 w-[400px] h-[600px] bg-white rounded-lg shadow-xl z-50">
      <div className="absolute -top-2 -right-2">
        <button
          onClick={() => setIsOpen(false)}
          className="bg-gray-100 hover:bg-gray-200 rounded-full p-1"
        >
          <X className="h-5 w-5 text-gray-600" />
        </button>
      </div>
      <SalesAssistant />
    </div>
  );
}