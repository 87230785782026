import { supabase } from './config';
import { sampleCustomers } from '../../data/sampleCustomers';
import { defaultTemplates } from '../../data/defaultTemplates';
import { v4 as uuidv4 } from 'uuid';

const DEFAULT_SETTINGS = {
  email: {
    enabled: true,
    server: 'smtp.gmail.com',
    port: 587,
    username: 'leads@autocrm.pro',
    tls: true
  },
  businessHours: {
    schedule: [
      { day: 'Monday', isOpen: true, openTime: '09:00', closeTime: '18:00' },
      { day: 'Tuesday', isOpen: true, openTime: '09:00', closeTime: '18:00' },
      { day: 'Wednesday', isOpen: true, openTime: '09:00', closeTime: '18:00' },
      { day: 'Thursday', isOpen: true, openTime: '09:00', closeTime: '18:00' },
      { day: 'Friday', isOpen: true, openTime: '09:00', closeTime: '18:00' },
      { day: 'Saturday', isOpen: true, openTime: '10:00', closeTime: '16:00' },
      { day: 'Sunday', isOpen: false, openTime: '09:00', closeTime: '18:00' }
    ],
    holidays: []
  },
  leadDistribution: {
    mode: 'round-robin',
    notifyViaSMS: true,
    notifyViaEmail: true,
    timeoutMinutes: 5,
    enableAfterHours: false
  },
  dealership: {
    name: 'AutoCRM Demo Dealership',
    address: '123 Main Street',
    city: 'Halifax',
    state: 'Nova Scotia',
    zipCode: 'B3H 1A1',
    phone: '(902) 555-0123',
    email: 'info@autocrm.pro',
    website: 'https://autocrm.pro'
  }
};

export async function seedDatabase() {
  try {
    console.log('Starting database seeding...');
    
    // First, execute schema SQL
    const { error: schemaError } = await supabase.rpc('setup_schema', {
      schema_sql: `
        -- Enable UUID extension
        create extension if not exists "uuid-ossp";
        
        -- Create core tables
        create table if not exists settings (
          id uuid primary key default uuid_generate_v4(),
          type text not null unique,
          data jsonb not null default '{}'::jsonb,
          created_at timestamptz default now(),
          updated_at timestamptz default now()
        );
        
        create table if not exists users (
          id uuid primary key default uuid_generate_v4(),
          name text not null,
          email text unique not null,
          role text not null default 'sales',
          permissions jsonb default '{"canManageLeads": true, "canManageCustomers": true}',
          status text not null default 'active',
          created_at timestamptz default now(),
          updated_at timestamptz default now()
        );
        
        create table if not exists templates (
          id uuid primary key default uuid_generate_v4(),
          name text not null unique,
          type text not null,
          subject text,
          content text not null,
          category text not null,
          variables jsonb default '[]'::jsonb,
          is_default boolean default false,
          created_at timestamptz default now(),
          updated_at timestamptz default now()
        );
        
        -- Enable RLS
        alter table settings enable row level security;
        alter table users enable row level security;
        alter table templates enable row level security;
        
        -- Create RLS policies
        create policy if not exists "Anyone can view settings"
          on settings for select using (true);
        
        create policy if not exists "Anyone can update settings"
          on settings for update using (true);
        
        create policy if not exists "Anyone can view users"
          on users for select using (true);
        
        create policy if not exists "Anyone can view templates"
          on templates for select using (true);
      `
    });

    if (schemaError) {
      console.error('Error setting up schema:', schemaError);
      throw schemaError;
    }

    // Seed settings
    const { data: existingSettings } = await supabase
      .from('settings')
      .select('type')
      .limit(1);

    if (!existingSettings?.length) {
      console.log('Seeding settings...');
      const { error: settingsError } = await supabase
        .from('settings').insert(
        Object.entries(DEFAULT_SETTINGS).map(([type, data]) => ({
          type,
          data,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        }))
      );

      if (settingsError) {
        console.error('Error seeding settings:', settingsError);
        throw settingsError;
      }
      console.log('Settings seeded successfully');
    }

    // Seed templates
    const { error: templatesError } = await supabase
      .from('templates')
      .insert(
        defaultTemplates.map(template => ({
          id: template.id,
          name: template.name,
          type: template.type,
          subject: template.subject || null,
          content: template.content,
          category: template.category,
          variables: template.variables,
          variables: Array.isArray(template.variables) ? template.variables : [],
          is_default: template.isDefault || false,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        }))
      ).onConflict('name').ignore();

    if (templatesError) {
      console.error('Error seeding templates:', templatesError);
      throw templatesError;
    }
    console.log('Templates seeded successfully');
    return true;

    console.log('Database seeding completed successfully');
    return true;
  } catch (error) {
    console.error('Error seeding database:', error);
    return false;
  }
}