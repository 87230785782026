import { Template } from '../types/template';

export const defaultTemplates: Template[] = [
  {
    id: 'welcome-email',
    name: 'Welcome Email',
    type: 'email',
    subject: 'Welcome to AutoCRM',
    content: `Dear {{customerName}},

Thank you for your interest in AutoCRM. We're excited to help you find your perfect vehicle.

Our team of experienced professionals is here to assist you throughout your car-buying journey. Would you like to schedule a time to discuss your preferences and requirements?

Best regards,
{{salesRepName}}
AutoCRM`,
    category: 'welcome',
    variables: ['customerName', 'salesRepName'],
    isDefault: true
  },
  {
    id: 'follow-up-email',
    name: 'First Follow-up',
    type: 'email',
    subject: 'Following up on your {{vehicleName}} inquiry',
    content: `Hi {{customerName}},

I wanted to follow up on your recent inquiry about the {{vehicleName}}. Are you available for a test drive this week?

I'd be happy to answer any questions you might have about the vehicle or discuss our current financing options.

Best regards,
{{salesRepName}}`,
    category: 'follow_up',
    variables: ['customerName', 'vehicleName', 'salesRepName'],
    isDefault: true
  },
  {
    id: 'test-drive-confirmation',
    name: 'Test Drive Confirmation',
    type: 'email',
    subject: 'Your test drive appointment for {{vehicleName}}',
    content: `Hi {{customerName}},

This email confirms your test drive appointment for the {{vehicleName}} on {{appointmentDate}} at {{appointmentTime}}.

Please bring your valid driver's license. We're located at {{dealershipAddress}}.

Looking forward to meeting you!

Best regards,
{{salesRepName}}`,
    category: 'follow_up',
    variables: ['customerName', 'vehicleName', 'appointmentDate', 'appointmentTime', 'dealershipAddress', 'salesRepName'],
    isDefault: true
  },
  {
    id: 'price-quote',
    name: 'Price Quote',
    type: 'email',
    subject: 'Price quote for {{vehicleName}}',
    content: `Dear {{customerName}},

Thank you for your interest in the {{vehicleName}}. As discussed, here is your personalized quote:

Vehicle: {{vehicleName}}
Price: {{price}}
Available Incentives: {{incentives}}
Financing Options: {{financingOptions}}

This quote is valid until {{validUntil}}. Would you like to schedule a time to discuss this further?

Best regards,
{{salesRepName}}`,
    category: 'follow_up',
    variables: ['customerName', 'vehicleName', 'price', 'incentives', 'financingOptions', 'validUntil', 'salesRepName'],
    isDefault: true
  },
  {
    id: 'thank-you-email',
    name: 'Purchase Thank You',
    type: 'email',
    subject: 'Thank you for choosing AutoCRM',
    content: `Dear {{customerName}},

Thank you for purchasing your new {{vehicleName}} from AutoCRM. We truly appreciate your business and trust in us.

Your first service appointment is scheduled for {{serviceDate}}. If you need to reschedule or have any questions about your new vehicle, please don't hesitate to reach out.

Best regards,
{{salesRepName}}
AutoCRM`,
    category: 'thank_you',
    variables: ['customerName', 'vehicleName', 'serviceDate', 'salesRepName'],
    isDefault: true
  },
  {
    id: 'service-reminder',
    name: 'Service Reminder',
    type: 'email',
    subject: 'Time for your {{vehicleName}} service',
    content: `Hi {{customerName}},

Just a friendly reminder that your {{vehicleName}} is due for its {{serviceType}} service.

Would you like to schedule a service appointment? We have several convenient time slots available next week.

Best regards,
AutoCRM Service Team`,
    category: 'service',
    variables: ['customerName', 'vehicleName', 'serviceType'],
    isDefault: true
  },
  {
    id: 'follow-up-sms',
    name: 'SMS Follow-up',
    type: 'sms',
    content: `Hi {{customerName}}, thanks for your interest in the {{vehicleName}}. Would you like to schedule a test drive? - {{salesRepName}}, AutoCRM`,
    category: 'follow_up',
    variables: ['customerName', 'vehicleName', 'salesRepName'],
    isDefault: true
  },
  {
    id: 'test-drive-sms',
    name: 'SMS Test Drive Reminder',
    type: 'sms',
    content: `Hi {{customerName}}, just confirming your test drive tomorrow at {{appointmentTime}} for the {{vehicleName}}. Please bring your driver's license. See you then! - {{salesRepName}}`,
    category: 'follow_up',
    variables: ['customerName', 'appointmentTime', 'vehicleName', 'salesRepName'],
    isDefault: true
  },
  {
    id: 'price-quote-sms',
    name: 'SMS Price Quote',
    type: 'sms',
    content: `Hi {{customerName}}, as discussed, the {{vehicleName}} is priced at {{price}}. This includes {{incentives}}. Let me know if you'd like to discuss financing options! - {{salesRepName}}`,
    category: 'follow_up',
    variables: ['customerName', 'vehicleName', 'price', 'incentives', 'salesRepName'],
    isDefault: true
  },
  {
    id: 'thank-you-sms',
    name: 'SMS Thank You',
    type: 'sms',
    content: `Thank you for purchasing your new {{vehicleName}}, {{customerName}}! Your first service is scheduled for {{serviceDate}}. If you need anything, don't hesitate to reach out! - {{salesRepName}}`,
    category: 'thank_you',
    variables: ['customerName', 'vehicleName', 'serviceDate', 'salesRepName'],
    isDefault: true
  },
  {
    id: 'service-reminder-sms',
    name: 'SMS Service Reminder',
    type: 'sms',
    content: `Hi {{customerName}}, your {{vehicleName}} is due for {{serviceType}}. Would you like to schedule service this week? Reply YES for available times. - AutoCRM Service`,
    category: 'service',
    variables: ['customerName', 'vehicleName', 'serviceType'],
    isDefault: true
  }
];

export const getTemplatesByType = (type: 'email' | 'sms'): Template[] => {
  return defaultTemplates.filter(template => template.type === type);
};