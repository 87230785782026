import React, { useState } from 'react';
import { Menu, User, LogOut, RotateCcw } from 'lucide-react';
import { NotificationCenter } from './notifications/NotificationCenter';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

interface TopBarProps {
  onMenuClick: () => void;
}

export function TopBar({ onMenuClick }: TopBarProps) {
  const { user, rollback } = useAuth();
  const navigate = useNavigate();
  const [isRollingBack, setIsRollingBack] = useState(false);

  const handleRollback = async () => {
    if (window.confirm('Are you sure you want to roll back? This will sign you out.')) {
      setIsRollingBack(true);
      try {
        await rollback();
        navigate('/login');
      } catch (error) {
        console.error('Rollback failed:', error);
      } finally {
        setIsRollingBack(false);
      }
    }
  };

  return (
    <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-border bg-background px-4 shadow-soft sm:gap-x-6 sm:px-6 lg:px-8">
      <button
        type="button"
        className="-m-2.5 p-2.5 text-foreground lg:hidden"
        onClick={onMenuClick}
      >
        <span className="sr-only">Open sidebar</span>
        <Menu className="h-6 w-6" aria-hidden="true" />
      </button>

      <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
        <div className="flex flex-1" />
        <div className="flex items-center gap-x-4 lg:gap-x-6">
          <NotificationCenter />

          <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" aria-hidden="true" />

          <div className="flex items-center gap-x-4">
            <div className="flex items-center gap-x-2">
              <User className="h-6 w-6 text-primary" aria-hidden="true" />
              <span className="text-sm font-medium text-foreground">
                {user?.displayName || 'User'}
              </span>
            </div>

            <button
              onClick={handleRollback}
              disabled={isRollingBack}
              className="rounded-md p-1 text-gray-400 hover:text-gray-500"
              title="Roll back changes"
            >
              <RotateCcw className={`h-5 w-5 ${isRollingBack ? 'animate-spin' : ''}`} />
            </button>

            <button
              onClick={() => navigate('/login')}
              className="rounded-md p-1 text-gray-400 hover:text-gray-500"
            >
              <LogOut className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}