import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Layout } from './components/Layout';
import { AuthProvider } from './context/AuthContext';
import { NotificationProvider } from './context/NotificationContext';
import { NetworkStatus } from './components/NetworkStatus';
import { AppRoutes } from './routes';
import { seedDatabase } from './lib/supabase/seed';

function App() {
  useEffect(() => {
    // Initialize databases
    const initDatabases = async () => {
      try {
        await seedDatabase();
      } catch (err) {
        // Log error but don't throw - allow app to continue
        console.warn('Database initialization error:', err instanceof Error ? err.message : 'Unknown error');
      }
    };
    
    initDatabases();
  }, []);

  return (
    <BrowserRouter>
      <AuthProvider>
        <NotificationProvider>
          <Layout>
            <NetworkStatus />
            <AppRoutes />
          </Layout>
        </NotificationProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;