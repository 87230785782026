import React, { Suspense, lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Loader2 } from 'lucide-react';
const Landing = lazy(() => import('../pages/Landing').then(m => ({ default: m.Landing })));
const ReviewRedirect = lazy(() => import('../pages/ReviewRedirect').then(m => ({ default: m.ReviewRedirect })));

// Lazy load pages with explicit exports
const Dashboard = lazy(() => import('../pages/Dashboard').then(m => ({ default: m.Dashboard })));
const Leads = lazy(() => import('../pages/Leads').then(m => ({ default: m.Leads })));
const Customers = lazy(() => import('../pages/Customers').then(m => ({ default: m.Customers })));
const Inventory = lazy(() => import('../pages/Inventory').then(m => ({ default: m.Inventory })));
const EmailPage = lazy(() => import('../pages/EmailPage').then(m => ({ default: m.EmailPage })));
const SMSPage = lazy(() => import('../pages/SMSPage').then(m => ({ default: m.SMSPage })));
const Reports = lazy(() => import('../pages/Reports').then(m => ({ default: m.Reports })));
const Settings = lazy(() => import('../pages/Settings').then(m => ({ default: m.Settings })));
const Assistant = lazy(() => import('../pages/Assistant').then(m => ({ default: m.Assistant })));
const VINDecoder = lazy(() => import('../pages/VINDecoder').then(m => ({ default: m.VINDecoder })));
const WebsiteAnalytics = lazy(() => import('../pages/WebsiteAnalytics').then(m => ({ default: m.WebsiteAnalytics })));
const Reviews = lazy(() => import('../pages/Reviews').then(m => ({ default: m.Reviews })));
const VideoPlayer = lazy(() => import('../pages/VideoPlayer').then(m => ({ default: m.VideoPlayer })));
const CarSearch = lazy(() => import('../pages/CarSearch').then(m => ({ default: m.CarSearch })));
const VehicleDatabase = lazy(() => import('../pages/VehicleDatabase').then(m => ({ default: m.VehicleDatabase })));
const VehicleDescriptionGeneratorPage = lazy(() => import('../pages/VehicleDescriptionGenerator').then(m => ({ default: m.VehicleDescriptionGeneratorPage })));
const AITools = lazy(() => import('../pages/AITools').then(m => ({ default: m.AITools })));

// Lazy load AI components
const SalesCoach = lazy(() => import('../components/ai/SalesCoach').then(m => ({ default: m.default })));
const VehicleRecommender = lazy(() => import('../components/ai/VehicleRecommender').then(m => ({ default: m.default })));
const CustomerInsights = lazy(() => import('../components/ai/CustomerInsights').then(m => ({ default: m.default })));
const PersonalizedEmail = lazy(() => import('../components/ai/PersonalizedEmail').then(m => ({ default: m.default })));
const MarketAnalyzer = lazy(() => import('../components/ai/MarketAnalyzer').then(m => ({ default: m.default })));
const CompetitorAnalysis = lazy(() => import('../components/ai/CompetitorAnalysis').then(m => ({ default: m.default })));
const PriceOptimizer = lazy(() => import('../components/ai/PriceOptimizer').then(m => ({ default: m.default })));
const InventoryOptimizer = lazy(() => import('../components/ai/InventoryOptimizer').then(m => ({ default: m.default })));
const CustomerSegmentation = lazy(() => import('../components/ai/CustomerSegmentation').then(m => ({ default: m.default })));
const ServicePredictor = lazy(() => import('../components/ai/ServicePredictor').then(m => ({ default: m.default })));

// Loading fallback component
const PageLoader = () => (
  <div className="flex items-center justify-center min-h-[50vh]">
    <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
  </div>
);

export function AppRoutes() {
  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/r/:trackingId" element={<ReviewRedirect />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/leads" element={<Leads />} />
        <Route path="/customers" element={<Customers />} />
        <Route path="/inventory" element={<Inventory />} />
        <Route path="/email" element={<EmailPage />} />
        <Route path="/sms" element={<SMSPage />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/assistant" element={<Assistant />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/vin-decoder" element={<VINDecoder />} />
        <Route path="/website-analytics" element={<WebsiteAnalytics />} />
        <Route path="/ai-tools" element={<AITools />}>
          <Route path="sales-coach" element={<SalesCoach />} />
          <Route path="recommender" element={<VehicleRecommender />} />
          <Route path="insights" element={<CustomerInsights />} />
          <Route path="email" element={<PersonalizedEmail />} />
          <Route path="market-analyzer" element={<MarketAnalyzer />} />
          <Route path="competitor-analysis" element={<CompetitorAnalysis />} />
          <Route path="price-optimizer" element={<PriceOptimizer />} />
          <Route path="inventory-optimizer" element={<InventoryOptimizer />} />
          <Route path="customer-segmentation" element={<CustomerSegmentation />} />
          <Route path="service-predictor" element={<ServicePredictor />} />
        </Route>
        <Route path="/video/:videoId" element={<VideoPlayer />} />
        <Route path="/car-search" element={<CarSearch />} />
        <Route path="/vehicle-description-generator" element={<VehicleDescriptionGeneratorPage />} />
        <Route path="/vehicle-database" element={<VehicleDatabase />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Suspense>
  );
}