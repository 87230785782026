import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { X, ChevronDown } from 'lucide-react';
import { classNames } from '../utils/classNames';
import { motion, AnimatePresence } from 'framer-motion';

interface SidebarProps {
  navigation: Array<{
    name: string;
    href: string;
    icon: React.ComponentType<any>;
    children?: Array<{
      name: string;
      href: string;
    }>;
  }>;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  currentPath: string;
}

export function Sidebar({ navigation, isOpen, setIsOpen, currentPath }: SidebarProps) {
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const navigate = useNavigate();

  const toggleExpand = (name: string) => {
    setExpandedItems(prev => 
      prev.includes(name) 
        ? prev.filter(item => item !== name)
        : [...prev, name]
    );
  };

  return (
    <>
      <div
        className={classNames(
          isOpen ? 'block' : 'hidden',
          'lg:hidden fixed inset-0 z-40 bg-gray-600/75 backdrop-blur-sm transition-opacity ease-linear duration-300'
        )}
        onClick={() => setIsOpen(false)}
      />

      <div
        className={classNames(
          isOpen ? 'translate-x-0' : '-translate-x-full',
          'fixed inset-y-0 flex w-72 flex-col lg:translate-x-0 z-50 lg:z-auto transition ease-in-out duration-300 bg-background border-r border-border'
        )}
      >
        <div className="flex grow flex-col gap-y-5 overflow-y-auto px-6 pb-4">
          <div className="flex h-16 shrink-0 items-center">
            <Link to="/" className="flex items-center">
              <span className="text-xl font-semibold text-primary">
                AutoCRM
              </span>
            </Link>
          </div>
          
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="-mx-2 space-y-1">
              {navigation.map((item) => (
                <li key={item.name} className="space-y-1">
                  <div
                    onClick={() => {
                      if (item.children) {
                        toggleExpand(item.name);
                      } else if (item.href && item.href !== '#') {
                        navigate(item.href);
                      }
                    }}
                    className={classNames(
                      currentPath === item.href
                        ? 'bg-primary/10 text-primary'
                        : 'text-muted-foreground hover:text-primary hover:bg-primary/10',
                      'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold transition-all duration-150 cursor-pointer'
                    )}
                  >
                    <item.icon
                      className={classNames(
                        currentPath === item.href
                          ? 'text-primary'
                          : 'text-muted-foreground group-hover:text-primary',
                        'h-6 w-6 shrink-0 transition-colors duration-150'
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                    {item.children && (
                      <ChevronDown 
                        className={classNames(
                          "ml-auto h-5 w-5 transition-transform duration-200",
                          expandedItems.includes(item.name) ? "transform rotate-180" : ""
                        )}
                      />
                    )}
                  </div>
                  <AnimatePresence>
                    {item.children && expandedItems.includes(item.name) && (
                      <motion.ul
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className="mt-1 space-y-1 pl-10 overflow-hidden"
                      >
                        {item.children.map((child) => (
                          <li key={child.name}>
                            <Link
                              to={child.href}
                              className={classNames(
                                currentPath === child.href
                                  ? 'bg-primary/10 text-primary'
                                  : 'text-muted-foreground hover:text-primary hover:bg-primary/10',
                                'block rounded-md py-2 px-3 text-sm leading-6 transition-all duration-150'
                              )}
                            >
                              {child.name}
                            </Link>
                          </li>
                        ))}
                      </motion.ul>
                    )}
                  </AnimatePresence>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}