import React, { useState, useEffect } from 'react';
import { Wifi, WifiOff } from 'lucide-react';

export function NetworkStatus() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  if (isOnline) {
    return null;
  }

  return (
    <div className="fixed bottom-4 left-4 bg-red-50 p-4 rounded-lg shadow-lg z-50">
      <div className="flex items-center">
        <WifiOff className="h-5 w-5 text-red-400 mr-2" />
        <div>
          <p className="text-sm font-medium text-red-800">
            You're offline
          </p>
          <p className="text-sm text-red-600">
            Please check your internet connection
          </p>
        </div>
      </div>
    </div>
  );
}