import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const supabaseUrl = 'https://lfmzvtytjglwuuguskwi.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxmbXp2dHl0amdsd3V1Z3Vza3dpIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzE2OTEzMDIsImV4cCI6MjA0NzI2NzMwMn0.n-bBjWCqt3qqDH2v4ISb5Tck1h18kMBDY8tiNVM2Hng';

if (!supabaseUrl || !supabaseKey) {
  throw new Error('Missing Supabase credentials. Please check your environment variables.');
}

export const supabase = createClient(supabaseUrl, supabaseKey, {
  auth: {
    persistSession: true,
    autoRefreshToken: true,
    detectSessionInUrl: true
  },
  global: {
    headers: { 'x-my-custom-header': 'my-app-name' }
  }
});